import React, { Component, Fragment } from 'react';

class Footer extends Component {
    render() {
        return <Fragment>

            <footer>
                © 2020 Copyright Andres Pulido
            </footer>

        </Fragment>
         
    }
}

export default Footer;